@charset "UTF-8";

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:600,700');


html {
  position: relative;
  height: 100vh;
  font-size: 8px;
}
body {
  font-family: 'Roboto', sans-serif;
  line-height: 26px;
  height: 100%;
  background-color: #fff;
  color: #676a6c;
	text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
}
ul {
  padding: 15px 0 0 15px;
}
li {
  line-height: 1.5em;
  margin-top: 5px;
}
a, a:link {
  text-decoration: none;
  font-weight: 700;
  color: #0FB245;
}
a:active, a:focus, a:hover {
  color: #0FB245;
}
a.text {
  display: inline-block;
  border-bottom: 1px solid #fff;
}
a:focus, a:active,
button::-moz-focus-inner,
button:focus,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  border: 0;
  outline : 0 !important;
}
label {
  display: block;
  padding: 15px 0 0px;
	font-size: 0.9em;
	font-weight: 500;
  color: #1E79C1;
  margin: 0;
}
label.grey {
  color: #ccc;
}
input[type="submit"], button {
	box-sizing: border-box;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
/* Reset IOS input box styling */
input {
  -webkit-appearance: none;
  border-radius: 0;
}
input[type="text"], input[type="email"], input[type="password"], input[type="number"], textarea, select {
  box-shadow: none;
  font-family: Roboto, sans-serif;
  border: 0;
  font-size: 1.2em;
  padding: 5px 10px 2px;
  width: 100%;
	display: block;
	box-sizing: border-box;
  font-weight: 500;
	color: #444;
	margin-bottom: 10px;
  background: #fff no-repeat;
  background-image: linear-gradient(to bottom, #1E79C1, #1E79C1), linear-gradient(to bottom, #e7eaec, #e7eaec);
  background-size: 0 2px, 100% 2px;
  background-position: 50% 100%, 50% 100%;
  transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
}
input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, input[type="number"]:focus, textarea:focus, select:focus {
  background-size: 100% 2px, 100% 2px;
  outline: none;
}
input[type="checkbox"]{
  -webkit-appearance: checkbox;
}
input[type="radio"]{
  -webkit-appearance: radio;
}
/* Reset webkit yellow background on autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    appearance: none;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-text-fill-color: #676a6c !important;
    border-bottom: 2px solid #e7eaec;
    color: #222;
}
/* Remove Webkit glow */
textarea, input { outline: 0 !important; }
/* Remove webkit focus glow */
:focus {
  outline-color: transparent;
  outline-style: none;
  outline: 0 !important;
}
textarea {
  resize: none;
  width: 100%;
  min-height: 250px;
  border: 2px solid #e7eaec;
  padding: 5px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
	color: #444;
  font-size: 1.1em;
}

input[type="text"].error, input[type="password"].error {
  border-bottom: 2px solid #DC0000;
}
select {
	margin-right: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  display: inline-block;
  width: auto;
}
input[type="password"] {
  font-size: 2em;
  letter-spacing: 1px;
}

/* Explicitly specify font-weight for all headings */
h1,h2,h3,h4,h5,h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #676a6c;
}
h1 {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.2em;
}
h2 {
  font-size: 24px;
  font-weight: 300;
  margin: 0;
}
h3 {
  font-size: 21px;
  line-height: 30px;
  margin: 10px 0;
  font-weight: 700;
}
h4 {
  font-size: 1.4em;
  font-weight: 700;
}
h5 {
  font-size: 1em;
  line-height: 1.2em;
  margin: 10px 0 0;
  padding: 0;
  font-weight: 700;
}
h6 {
  font-size: 0.8em;
  margin: 0 0 10px;
  line-height: 1em;
}
p {
  line-height: 1.3em;
  margin: 15px 0;
}
input[type="text"]:disabled {
  color: #ccc;
}
input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */
::-webkit-input-placeholder {
  color: #ccc;
}
:-moz-placeholder {
  color: #ccc;
}
::-moz-placeholder {
  color: #ccc;
}
:-ms-input-placeholder {
  color: #ccc;
}

strong {
  color: #333;
  font-weight: 700;
}

svg {
	height: 18px;
	fill: #444;
	margin: 0 15px;
	vertical-align: middle;
	transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

/* Widgets */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
	margin-top: 10px;
}
.switch input {display:none;}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #2196F3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}


/* WOW */
.wow {
  visibility: hidden;
}


/* Global */
.inline {
	display: inline-block;
}
.msg {
	min-height: 1em;
  font-weight: 700;
}
.msg.inline {
  margin-left: 10px;
}
.icon {
  vertical-align:middle;
  padding-right: 8px;
}
.btn {
  font-family:'Camphor W01 Medium';
  font-weight: 700;
  display: inline-block;
  line-height: normal;
  padding: 15px 15px;
  text-transform: uppercase;
  color: #fff;
	border: 0;
  border-radius: 2px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1em;
  letter-spacing: 1px;
  background-position: 99%;
  background-size: 400%;
  background-color: #fff;
  border-style: solid;
  color: #333;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
	position: relative;
	overflow: hidden;
}
.btn.inline {
  margin-top: 0;
}
.btn.btn-none {
  border: none;
  padding: 0;
  text-transform: none;
}
.btn.btn-none.green {
  color: #0FB245;
  background-color: inherit;
  font-size: 0.9em;
}
.btn.blue, input.btn.blue {
  background-color: #1E79C1;
  border: 3px solid #1E79C1;
	color: #fff;
}
.btn:hover, .svgbtn:hover {
	cursor: pointer;
}
.btn.green:hover:disabled {
  color: #333;
  background-color: #ccc;
}
.btn.green {
  background-color: #0FB245;
  border-width: 3px;
  border-color: #0FB245;
  color: #fff;
}
.btn.green.colorflip {
  color: #0FB245;
  border-color: #0FB245;
  background-color: #fff;
}
.btn.green.colorflip:hover {
  color: #fff;
  background-color: #0FB245;
}
.btn.red {
  background-color: #CE452D;
  border-width: 3px;
  border-color: #CE452D;
  color: #fff;
}
.btn.tx {
	color: #ccc;
}
.btn.small {
  padding: 10px 8px;
  font-size: 0.8em;
  border-width: 3px;
}
.btn.tiny {
  font-weight: 600;
  padding: 7px 3px;
  font-size: 9px;
  border-radius: 2px;
  border-width: 1px;
  vertical-align: middle;
  margin: 0;
}

.btn:disabled,
button:disabled,
input[type="submit"]:disabled {
  background-color: #ccc;
  border: 3px solid #ccc;
  color: #999;
	cursor: default;
}
.btn.tiny:disabled {
  border-width: 1px;
}
.bluesvg {
  fill: #1E79C1;
}
.greensvg {
  fill: #0FB245;
}
.redsvg {
  fill: #CE452D;
}
.greysvg {
  fill: #CCCCCC;
}
.greysvg:hover {
  fill: #666666;
}
.red {
  color: #CE452D;
}
.green {
  color: #0FB245;
}
.blue {
  color: #1E79C1;
}
.white {
  color: #fff;
}
.grey {
  color: #999;
}
.rotate45 {
	transform: rotate(45deg);
}
.text-left {
  text-align: left;
}


/* Responsive Framework */
.gutter-2.row {
	margin-right: -1px;
	margin-left: -1px;
}
.gutter-2 > [class^="col-"], .gutter-2 > [class^=" col-"] {
	padding-right: 1px;
	padding-left: 1px;
}
.gutter-6.row {
	margin-right: -3px;
	margin-left: -3px;
}
.gutter-6 > [class^="col-"], .gutter-6 > [class^=" col-"] {
	padding-right: 3px;
	padding-left: 3px;
}
.img-responsive {
  max-width: 100%;
  height: auto;
  display: block;
}
.flexrow {
  flex-direction: row;
}
.flexcol {
  flex-direction: column;
}
.text-center {
	text-align: center;
}
.show-xs {
  display: none;
}




/* Container */
#main {
  height: 100%;
	width: 100%;
}



/* UX components */

.card {
  display: block;
	position: relative;
  min-height: 170px;
  background-color: #fff;
  margin: 5px;
  border-top: 2px solid #e7eaec;
  border-left: 2px solid #f3f3f4;
  border-right: 2px solid #f3f3f4;
  border-bottom: 2px solid #f3f3f4;
	box-sizing: border-box;
  border-radius: 4px;
}
.card.tx {
	background-color: transparent;
	border-top: none;
}
a.card:hover {
  border: 2px solid #0FB245;
}
.card .cardhead {
  display: flex;
  align-items: center;
}
.card .cardhead .cardaction {
  margin-left: auto;
  padding-right: 10px;
}
.card .cardbody {
  min-height: 70px;
  padding: 5px 10px 15px 10px;
}
.card .cardcontent > div {
	width: 100%;
  align-self: stretch;
}
.card .cardhead h4 {
  padding-left: 10px;
  line-height: 0.9em;
  margin: 20px 0;
}
.card h1 {
  margin: 0;
}
.card p {
  margin: 0;
}
.card hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
  width: 100%;
  height: 0;
}
.saveform {
  margin-top: 20px;
}

.datatable {
  display: table;
  width: 100%;
}
.datatable .tablehead {
  display: table-header-group;
}
.datatable .tablehead .tablerow .tablecell {
  font-size: 1.1em;
  font-weight: 700;
  color: #1E79C1;
}
.datatable .tablebody {
  display: table-row-group
}
.datatable .tablerow {
  display: table-row;
}
.datatable .tablebody .tablerow:nth-child(even) {
  background-color: #f9f9f9;
}
.datatable .tablebody .tablerow.link:hover {
  cursor: pointer;
  background-color: #0FB245;
}
.datatable .tablebody .tablerow.link:hover .tablecell {
  color: #fff;
}
.datatable .tablecell {
  display: table-cell;
  padding: 14px 5px;
  color: #676a6c;
  border-bottom: 1px solid #e7eaec;
  font-weight: 500;
  line-height: 1.1em;
  overflow: hidden;
  vertical-align: middle;
}
.datatable .tablebody .tablecell {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.datatable .tablebody .tablerow.active .tablecell {
  font-weight: 700;
}
.tblimage > div {
  height: 60px;
  width: 60px;
  display: block;
}
.tblimage img {
  height: 60px;
}



/* Loaders */

#loadwrap {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 10000;
  transition: width 0.1s ease-out 0s;
  -webkit-transition: width 0.1s ease-out;
  -moz-transition: width 0.1s ease-out;
  -ms-transition: width 0.1s ease-out;
  -o-transition: width 0.1s ease-out;
}
#load {
  background-color: #ddd;
  height: 1px;
  position: relative;
  width: 100%;
  top: 49%;
}
#load > div {
  background-color: #000;
  height: 100%;
  width: 0%;
  transition: width 0.1s ease-out 0s;
  -webkit-transition: width 0.1s ease-out;
  -moz-transition: width 0.1s ease-out;
  -ms-transition: width 0.1s ease-out;
  -o-transition: width 0.1s ease-out;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.loader {
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #1E79C1;
  border-left: 3px solid #1E79C1;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.isloading {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	background-color: rgba(255,255,255,0.8);
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}
.isloading.inline {
  display: inline-block;
	width: auto;
	position: relative;
	top: 10px;
  left: 10px;
}
.isloading.block {
	display: block;
  margin: 0 auto;
	width: auto;
	position: relative;
	top: 10px;
}





/* Login Form */

.loginwrap {
  text-align: center;
}
.loginwrap .logo {
  color: #333;
  font-size: 55px;
  letter-spacing: -6px;
  text-shadow: 2px 5px 5px rgba(51,51,51,0.1);
  display: block;
  margin-bottom: 50px;
}
.loginwrap svg {
  height: 140px;
  margin-bottom: 20px;
}
.login {
  margin: 0 auto;
  min-height: 100vh;
  padding-top: 10px;
  text-align: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
}
.login > div {
  text-align: center;
  margin: 0 auto;
}
.loginbox {
  padding: 20px 15px;
  border: 1px solid rgba(204,204,204,0.5);
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 16px rgba(51,51,51,0.1), -2px -2px 16px rgba(51,51,51,0.1);
}
.loginbox h5 {
  margin: 10px 0;
}
.loginbox p {
  margin: 5px 0 10px;
  color: #999;
}
.loginbox a.forgot {
  font-size: 13px;
  display: block;
  margin: 5px 0 15px;
}
.loginbox input {
  font-size: 1.2em;
  font-weight: 600;
}
.login > div a.btn {
  margin: 20px 0;
}
.loginwrap p {
  font-size: 14px;
}
.loginwrap p a {
  border-bottom: none;
}
.loginwrap .message {
  margin-top: 1em;
  min-height: 3em;
  line-height: 1.3em;
  text-align: center;
  font-size: 13px;
  color: #00A651;
  font-weight: 600;
}







/* Header */

.headerwrap {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 3;
	left: 200px;
  transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  height: 70px;
	border-bottom: 1px solid #e7eaec;
  box-sizing: content-box;
}

.header h2 {
  display: inline-block;
  vertical-align: middle;
}

.header .user, .header .admin {
  line-height: 1.3em;
  font-weight: 700;
  max-width: calc(100% - 190px);
  min-width: 180px;
  color: #676a6c;
}
.header .admin input {
  border: none;
  font-weight: 700;
  color: #676a6c;
  font-size: 1.4em;
}
.header .admin input::selection {
  background: #ccc;
}
.header .admin .adminmenu {
  padding: 5px;
  box-shadow: none;
  background: #fff;
  border-radius: 0;
  position: fixed;
  overflow: auto;
  max-height: 50%;
  max-width: calc(100% - 190px);
  min-width: 180px;
}

.header .admin .adminitem {
  padding: 10px;
  color: #676a6c;
  font-weight: 700;
}

.navbtn {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  height:40px;
  width:40px;
  padding: 5px;
  margin: 0 20px;
	background-color: #fff;
  border-radius: 3px;
  transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
}
.navbtn:hover {
	background-color: #f3f3f4;
	cursor: pointer;
}
.navbtn span {
  display: block;
  position: relative;
  top: 13px;
  background-color: #ccc;
  border-radius: 2px;
  height: 5px;
  transition: background 0s 0.3s;
  -moz-transition: background 0s 0.3s;
  -webkit-transition: background 0s 0.3s;
  -o-transition: background 0s 0.3s;
}
.navbtn span:before, .navbtn span::after {
  display: block;
  position: relative;
  background-color: #ccc;
  border-radius: 2px;
  height: 5px;
  content: "";
  transition-duration: 0.3s, 0.3s;
  -moz-transition-duration: 0.3s, 0.3s;
  -webkit-transition-duration: 0.3s, 0.3s;
  -o-transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0s;
  -moz-transition-delay: 0.3s, 0s;
  -webkit-transition-delay: 0.3s, 0s;
  -o-transition-delay: 0.3s, 0s;
}
.navbtn span::before {
  transition-property: top, transform;
  top: -10px;
}
.navbtn span::after {
  transition-property: bottom, transform;
  top: 5px;
}
.navbtn:hover { cursor:pointer; }


/* Menu, notification and order */
.header >div:nth-of-type(3) {
  position: fixed;
  right: 0;
  display: flex;
}
.noteshead, .orderhead, .refreshhead, .menuhead {
  display: inline-block;
}
/* Refresh */
.refreshhead:hover {
  background-color: #efefef;
  cursor: pointer;
}
.menu .refreshhead:hover {
  background-color: transparent;
}
.refreshhead .refresh.refreshing {
    -webkit-animation:spin 3s linear 3;
    -moz-animation:spin 3s linear 3;
    animation:spin 3s linear 3;
}
.refreshhead .refresh.refreshing svg {
	fill: #0FB245;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
/* Notifications */
.noteshead, .orderhead, .refreshhead {
  position: relative;
}
.noteshead:hover {
  background-color: #1E79C1;
  cursor: pointer;
}
.noteshead .notes, .refreshhead .refresh, .orderhead .cart {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 60px;
}
.noteshead .notes svg {
	height: 20px;
	fill: #ccc;
  margin: 0;
}
.noteshead:hover span.notecount {
  color: #fff;
  background-color: #CE452D;
}
.noteshead span.notecount {
  z-index: 1;
  font-size: 9px;
  color: #fff;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align:center;
  border-radius: 50%;
  background-color: #1E79C1;
  position: absolute;
  left: 32px;
  bottom: 34px;
  display: block;
  font-weight: 700;
}
/* Cart */
.orderhead .cart svg, .refreshhead .refresh svg {
	height: 30px;
	fill: #ccc;
  margin: 0;
}
.orderhead:hover {
  background-color: #0FB245;
  cursor: pointer;
}
.orderhead:hover svg, .noteshead:hover svg {
  fill: #fff;
}
.orderhead:hover span.cartcount {
  color: #0FB245;
  background-color: #fff;
}
.orderhead span.cartcount {
  z-index: 1;
  font-size: 10px;
  color: #fff;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align:center;
  border-radius: 50%;
  background-color: #0FB245;
  position: absolute;
  left: 19px;
  bottom: 33px;
  display: block;
  font-weight: 700;
}
.order .offer {
  flex-direction: row;
  align-items: center;
}
.order .itemamount {
  text-align: center;
}


/* Menu */

.menubtn {
  display: flex;
  margin-left: auto;
  width: 60px;
  height: 70px;
  align-items: center;
  transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
}
.menubtn > div {
  display: inline-block;
  margin: 0 auto;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #ccc;
  box-shadow: 0px 10px 0px #ccc, 0px -10px 0px #ccc;
}
.menubtn:hover, .active .menubtn {
  background-color: #222;
  cursor: pointer;
}
.menubtn:hover > div, .active .menubtn > div {
  background-color: #ccc;
  box-shadow: 0px 10px 0px #ccc, 0px -10px 0px #ccc;
}
.menu {
  display: none;
  position: fixed;
  top: 70px;
  right: 0;
  width: 200px;
	min-height: 100%;
  z-index: 2;
  max-width: 90%;
  height: calc(100% - 110px);
  background-color: #222;
  transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  overflow: scroll;
  padding-bottom: 90px;
}
.active .menu {
  display: block;
}
.menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.menu ul li {
  margin: 0;
  display: block;
  padding: 20px 10px;
  line-height: 1.1em;
  font-weight: 700;
  color: #ccc;
}
.menu ul li.active {
  color: #fff;
  background-color: #0FB245;
}
.menu ul li:hover {
  cursor: pointer;
  color: #fff;
  background-color: #333;
}
.menu ul li.active:hover {
  background-color: #0FB245;
}
.menu ul li.section {
  border-top: 1px solid #333;
}
.menu ul li:first-of-type{
  display: none;
}
.menu ul li:first-of-type > a {
  width: 33%;
}

.menu ul li:last-of-type {
  border-bottom: 1px solid #333;
}
.menu ul li:last-of-type:hover {
	color: #fff;
  background-color: #1E79C1;
}


/* Forms */



.react-select {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin: 10px 0;
}

.react-select .react-select__control {
    align-items: center;
    background-color: white;
    border-radius: 3px;
    border-style: solid;
    box-shadow: none;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 40px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    border: 3px solid #efefef;
    padding-left: 8px;
}
.react-select__control.react-select__control--is-focused, .react-select__control--is-focused:hover {
  border: 3px solid #ccc;
}
.react-select .react-select__value-container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  font-weight: 600;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  border: none;
}
.react-select .react-select__input {
}
.react-select input {
  font-weight: 600;
}
.react-select__menu {
  border-radius: 3px;
  box-shadow: 1px 1px 4px -2px rgba(0,0,0,0.2);
  border: 3px solid #ccc;
  border-top: 0 none;
  top: 28px;
  z-index: 10;
}
.react-select .react-select__menu-list {
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}
.react-select .react-select__option {
    background-color: white;
    color: #444;
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    font-weight: 600;
    width: 100%;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    box-sizing: border-box;
}
.react-select .react-select__menu {
  border-radius: 3px;
  box-shadow: 1px 1px 4px -2px rgba(0,0,0,0.2);
  border: 3px solid #ccc;
  border-top: 0 none;
  top: 28px;
}
.react-select .react-select__menu-list {
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}






/* Footer */

.footer {
	line-height: 50px;
	height: 50px;
	text-align: right;
	background-color: #fff;
	border-top: 1px solid #e7eaec;
  width: 100%;
}
.footer p {
	color: #aaa;
	font-size: 12px;
	padding-right: 20px;
}
/* Footer Login */
/* Login Footer */
.footer-login {
  text-align: center;
  padding-top: 40px;
}
.footer-login p {
  font-size: 13px;
}
.footer-login a {
  font-size: 13px;
  display: inline-block;
}
.footer-login a:after {
  content: '\2219';
  color: #999;
  font-size: 20px;
  padding: 0 5px;
}
.footer-login a:last-of-type:after {
  content: '';
}
.footer-login span {
  display: inline-block;
  margin-top: 20px;
}


/* Content */

.content {
  height: 100%;
	width: 100%;
	background-color: #f3f3f4;
  display: flex;
}

.pill {
  box-sizing: border-box;
  height: 100%;
  max-height: 100vh;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  flex: none;
	transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
}
.pill:first-of-type {
  z-index: 10;
}
.pill:nth-of-type(2) {
  width: calc(100vw - 200px);
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.pill .view {
  padding-top: 80px;
	flex: 1;
  width: 100%;
}



/* Nav */

.nav {
  background-color: #222;
	width: 200px;
	z-index: 101;
	color: #fff;
	width: 200px;
	height: 100%;
  min-height: 100vh;
  transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
}
.nav .beta {
	display: inline-block;
  position: relative;
  left: 20px;
  top: -20px;
  transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
}
.mini .headerwrap {
	left: 60px;
}
.mini .nav .beta {
  opacity: 0;
}

.mini .nav {
	width: 60px;
}
.mini .pill:nth-of-type(2) {
  width: calc(100vw - 60px);
}
.mini .nav svg {
	height: 30px;
	margin: 0;
}
.nav .logowrap {
  height: 110px;
}
.nav .logowrap svg {
  height: 80px;
  padding: 20px 0 0;
  display: block;
  margin: 0 auto;
}
.mini .nav .logowrap svg {
  height: 50px;
  padding: 10px 0;
}
.mini .nav .logowrap {
  height: 60px;
}
.mini .nav .navitem span {
  opacity: 0;
  line-height: 1px;
  height: 0px;
  font-size: 1px;
}

.logowrap {
  text-align: center;
}
a.logo {
	display: block;
	transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  box-sizing: content-box;
}
#loginwrap .logo {
	color: #222;
}

a.navitem {
  box-sizing: content-box;
  padding: 15px;
  height: 40px;
	line-height: 40px;
	overflow: hidden;
	display: block;
	transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
	font-size: 14px;
  font-weight: 700;
	color: #ccc;
}
.navitem:hover {
	background-color: #333;
	color: #fff;
}
.navitem:hover svg {
  fill: #fff;
}
.navitem span {
	color: #ccc;
	vertical-align: middle;
	transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.navitem.active {
	color: #fff;
  background-color: #0FB245;
}
.navitem.active span {
	color: #fff;
}
.navitem.active svg {
	fill: #fff;
}

.dropzone {
  width: 100%;
  min-height: 200px;
  border: 4px dashed #efefef;
  margin-bottom: 30px;
}
.dz-message {
  text-align: center;
  padding: 20px 0;
}
.dz-message svg {
  height: 100px;
}
.dz-message button {
  margin: 0;
  padding: 0;
  text-transform: none;
  color: #0FB245;
}




/* Dashboard */

.dash {
  padding: 20px;
  margin-bottom: 40px;
}
.dash .card .cardcontent {
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
}
.domainstats {
  text-align: left;
}
.twitter span {
  display: block;
  line-height: 1em;
  font-weight: 700;
}
.balance {
  font-weight: 300;
  font-size: 32px;
}
.recharts svg, .recharts-responsive-container svg {
	height: auto;
	margin: 0;
	font-family: "Open Sans", Roboto, sans-serif;
	font-weight: 500;
	font-size: 11px;
}



/* Domains */
.domains .datatable .tablecell:nth-of-type(1){
  width: 50%;
}
.domains .datatable .tablecell:nth-of-type(3), .domains .datatable .tablecell:nth-of-type(3) {
  text-align: center;
}
.domains input[type="text"] {
  margin-top: 20px;
}
.domainbool button {
  margin-top: 20px;
}
.domainbool {
  margin: 20px 0 0;
}
.domainbool label {
  padding: 0;
}
.domainbool .quote {
  margin-bottom: 10px;
}
.domainbool .quote h1, .domainbool .quote label {
  display: inline-block;
}



/* Zone */
.records h4 {
  text-align: left;
}
.recordtype .tablecell:nth-of-type(3),
.recordtype .tablecell:nth-of-type(5) {
  text-align: center;
  width: 60px;
}
.recordtype .tablecell:nth-of-type(4) {
  text-align: center;
  width: 60px;
}
.recordtype .tablecell:last-of-type span {
  padding: 0;
  width: 0;
}
.recordtype .tablecell:last-of-type span svg {
  width: 0px;
  height: 0px;
  padding: 0;
  margin: 0;
}
.zoneinfo .cardbody label{
  padding-bottom: 10px;
}
.nameservers > div {
  text-align: left;
}
.nameservers .tablerow .tablecell:last-of-type {
	text-align: right;
}


/* Mailboxes */
.mailboxes .list .tablerow .tablecell:nth-of-type(2),
.mailboxes .list .tablerow .tablecell:nth-of-type(3) {
  text-align: center;
}
.mailboxes .list .tablebody .tablerow .tablecell:nth-of-type(2) {
  text-transform: uppercase;
}
.mailboxes .list label {
  padding: 5px 0;
}
.mbox input[type="submit"] {
  margin: 15px 10px 0;
}
.addmailbox label {
  padding: 0;
}
.addmailbox select {
  padding: 0;
  margin: 5px 0 10px 0;
  border-bottom: 0;
  font-weight: 400;
  vertical-align: top;
}



/* Mailbox */

input[type="text"].pw {
  -webkit-text-security: disc;
}
.resetpass input[type="submit"] {
  margin-top: 25px;
}
.datatable.clean .tablebody .tablerow:nth-child(even) {
  background: none;
}
.datatable.clean .tablecell {
  border-bottom: 0;
}
.remove .confirm {
  margin: 15px 0;
}
.remove .confirm h5 {
  display: inline-block;
  padding-left: 10px;
}




/* Billing */
.billdetails button {
  margin: 10px 5px 0 0;
}
.ccpay .visamc > div {
  text-align: center;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ccpay .visamc svg {
  width: 90%;
  height: auto;
  max-width: 120px;
}
.ccpay input[name="cc"] {
  font-size: 2em;
	font-weight: 300;
}
.ccpay input[name="amount"] {
  font-weight: 300;
  font-size: 32px;
}
.ccpay label {
  height: 3em;
}
.rw-dropdown-list .rw-widget-container {
  border: none;
  border-bottom: 2px solid #efefef;
  box-shadow: none;
}
.rw-dropdown-list .rw-input {
  font-size: 1.2em;
  color: #444;
  font-weight: 500;
}



/* Licenses */
.licensing .datatable .tablecell:nth-of-type(2), .licensing .datatable .tablecell:nth-of-type(3) {
  text-align: center;
}
.card.current .cardcontent {
  display: flex;
  text-align: center;
}
.card.offer .cardcontent {
  flex-direction: column;
  text-align: left;
}
.card.offer h1.price {
  margin: 0;
}
.offer h3 {
  margin: 0;
}
.offermeta h4 {
  margin-top: 10px;
  line-height: 1.2em;
}
.offermeta p {
  margin: 10px 0;
}

.offer label {
  padding: 0;
  font-size: 0.9em;
  line-height: 1.2em;
}
.offer .offeraction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.offer button{
  margin: 10px;
}
.offer button:disabled {
  cursor: auto;
}
.license .currentsubs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.license .currentsubs > div {
  flex: 1;
}
.license .removelicenses .amount {
  margin: 0 auto;
  text-align: center;
}
.license .removelicenses input[name="amount"] {
  max-width: 100px;
  text-align: center;
  margin: 0 auto;
  font-size: 1.6em;
}
.totalcost h1, .totalcost h6 {
  text-align: center;
}

.addalias input[type="text"], .addalias select {
  max-width: 45%;
  display: inline;
}
.addalias input[type="text"], .addalias select {
  padding: 10px 5px;
}
.addalias input[type="text"] {
  text-align: right;
}



/* Order */
.ordercount span {
  font-weight: 700;
  font-size: 1.2em;
}
.itemamount input {
  max-width: 70px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: inline-block;
  text-align: right;
  font-size: 1.6em;
}
.special label {
  margin: 10px 0;
}
.special button {
  margin-top: 20px;
}
.order .charge label {
  padding: 0;
}
.order .charge .emptycart {
  padding-top: 15px;
}
.orderhelp h2 {
  margin: 10px 0;
}
.orderhelp svg {
  height: 24px;
}


/* Checkout */
.checkout form {
  display: inherit;
}
.checkout .agree p {
  margin-bottom: 5px;
}
.checkout .agree input {
  margin-right: 15px;
}
.checkout .agree h5 {
  display: inline-block;
}
.checkout .alreadyms h5:first-of-type {
  margin-bottom: 10px;
}
.checkout button {
  margin-top: 10px;
}
.checkout svg {
  margin: 20px 0;
  height: 60px;
}
.methods .cardbody input[type="radio"],
.agree .cardbody input[type="checkbox"],
.methods .cardbody h5,
.methods .cardbody h4 {
  display: inline-block;
  margin-right: 10px;
}



/* Support */
.kblinks p {
  padding-bottom: 20px;
}
.kblinks label {
  padding-top: 0;
}
.kblink {
  line-height: 1.1em;
}
.kblink a {
  color: #0FB245;
  font-weight: 500;
}
.requests .tablebody .tablecell:nth-of-type(1),
.requests .tablebody .tablecell:nth-of-type(3) {
  font-weight: 500;
}
.requests .tablebody .tablecell:nth-of-type(4) {
  text-transform: uppercase;
  font-size: 9px;
  text-align: center;
}
.requests .tablebody .tablecell:nth-of-type(4) span{
  display: inline-block;
  border-radius: 3px;
  padding: 5px;
  color: #fff;
	background-color: #333;
}
.requests .tablebody .tablecell:nth-of-type(4) span.solved { background-color: #ccc; }
.requests .tablebody .tablecell:nth-of-type(4) span.open { background-color: #0FB245; }
.requests .tablebody .tablecell:nth-of-type(4) span.new { background-color: #1E79C1; }
.requests .tablebody .tablecell:nth-of-type(4) span.pending { background-color: #CE452D; }




/* Media queries */

/* Xtra small */
@media(max-width:767px){
  body,p {
    font-size: 12px
  }
  h4 {
    font-size: 1.2em;
  }
  .login {
    padding-top: 30px;
  }
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  .hidden-xs {
    display: none;
  }
  .show-xs {
    display: block;
  }
	.headerwrap {
		left: 0px;
	}
  .noteshead, .orderhead, .refreshhead {
    display: none;
  }
	.menu ul li:hover {
		background-color: transparent;
	}
  .menu .noteshead, .menu .orderhead, .menu .refreshhead {
    display: inline-block;
    vertical-align: top;
  }
  .menu ul li:first-of-type {
    padding: 0;
  }
  .menu ul li:first-of-type, .menu ul li:nth-of-type(2) {
    display: block;
  }

  .nav {
    width: 60px;
  }
  .nav .beta {
    display: none;
  }
  .nav svg {
    height: 30px;
    margin: 0;
  }
  .pill {
    position: relative;
    left: -60px;
  }
  .mini .pill {
    left: 0;
  }
  .pill:nth-of-type(2) {
    width: 100%;
    width: 100vw;
  }
  .nav svg {
    height: 30px;
    margin: 0;
  }
  .nav .logowrap svg {
    height: 50px;
    padding: 10px 0;
  }
  .nav .navitem span {
    opacity: 0;
    line-height: 1px;
    height: 0px;
    font-size: 1px;
  }

  .datatable .tablecell {
    overflow: hidden;
    padding: 15px 5px;
    font-size: 0.8em;
  }
  .recordtype .tablecell:nth-of-type(2) {
    max-width: 80px;
    font-size: 0.7em;

  }
  .recordtype .tablecell:nth-of-type(3),
  .recordtype .tablecell:nth-of-type(4) {
    width: 30px;
  }

  .ccpay .visamc svg {
    width: 70%;
  }



}

/* Small */
@media(min-width:768px){
  body,p {
    font-size: 13px
  }
  .container {
    width: 700px;
  }
}

/* Medium */
@media(min-width:992px){
  .container {
    width: 900px;
  }
}

/* Large */
@media(min-width:1200px){
  .container {
    width: 1100px;
  }
}

/* Xtra Large */
@media(min-width:1600px){
}




/* Hiding out */
@media (max-width: 575px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

/* Lock down to portrait only */
@viewport {
	orientation: portrait;
}



/* Grid helpers */
.justify-content-center {
  align-items: center;
  justify-content: center;
}
