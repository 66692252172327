@charset "UTF-8";
@font-face{
        font-family:"Camphor W01 Light";
        src:url("../fonts/697e25e7-3cce-44ca-bc13-052541349501.eot?#iefix");
        src:url("../fonts/697e25e7-3cce-44ca-bc13-052541349501.eot?#iefix") format("eot"),url("../fonts/942a0868-4392-4c69-85fa-6d77a3716d9b.woff2") format("woff2"),url("../fonts/9ff183ce-5d21-45c8-87f7-cc6a34c12be7.woff") format("woff"),url("../fonts/5d55e7e6-f5b4-4b79-8762-24d67e51aebc.ttf") format("truetype"),url("../fonts/8290f70f-acfa-4213-9fe9-1af4925844b3.svg#8290f70f-acfa-4213-9fe9-1af4925844b3") format("svg");
    }
    @font-face{
        font-family:"Camphor W01 Regular";
        src:url("../fonts/1a75b1be-660a-4bbb-89eb-ac51af5dd23c.eot?#iefix");
        src:url("../fonts/1a75b1be-660a-4bbb-89eb-ac51af5dd23c.eot?#iefix") format("eot"),url("../fonts/7afb413d-536f-439c-bcca-ba53e24cda07.woff2") format("woff2"),url("../fonts/4eea462d-03c9-489a-a928-185525ee60b0.woff") format("woff"),url("../fonts/b2be4b07-b8c4-4d15-beaa-8e29cc0d9b5c.ttf") format("truetype"),url("../fonts/a40635c8-a280-435d-9fb1-fa58d0579701.svg#a40635c8-a280-435d-9fb1-fa58d0579701") format("svg");
    }
    @font-face{
        font-family:"Camphor W01 Medium";
        src:url("../fonts/c33236ab-5b2c-4512-8e8d-55b46f10c20f.eot?#iefix");
        src:url("../fonts/c33236ab-5b2c-4512-8e8d-55b46f10c20f.eot?#iefix") format("eot"),url("../fonts/b94e9ece-f9bc-4208-9336-9cc0b44aee89.woff2") format("woff2"),url("../fonts/cad49c2a-ff92-4482-ac39-b71dbad15a8f.woff") format("woff"),url("../fonts/68ed309e-bb5d-4ace-9b76-8080c11daef9.ttf") format("truetype"),url("../fonts/da6ab5fa-3c06-4256-916c-26e111de8e37.svg#da6ab5fa-3c06-4256-916c-26e111de8e37") format("svg");
    }
